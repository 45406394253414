<template>
<div id="CameraSettings">
    <div class="position-relative">
        <iws-breadcrumbs
            :path="[{
                title: 'Devices',
                link: `/cameras/settings`
            }, {
                title: device?.deviceID,
                link: `/cameras/settings/${workOrderId}/${device?.deviceID}`
            }]"
        />

        <div class="page-title">
            Device Configuration
        </div>

        <div class="positioned-actions">
            <template v-if="canProvision">
                <iws-button type="outline-light" text="Keychain" :click="openAccountsModal" class="mr-3" />
                
                <iws-button type="primary" text="Provision" :disabled="!canProvision" :click="provision" />
            </template>
            <template v-else>
                <a :href="`/cameras/viewer/${workOrderId}`" class="mr-3">
                    <iws-button type="outline-primary" icon="far fa-eye" />
                </a>

                <iws-button type="outline-light" text="Keychain" :click="openAccountsModal" class="mr-3" />

                <iws-button type="outline-danger" text="Deprovision" :disabled="!canDeprovision" :click="deprovision" />
                <iws-button type="outline-danger" text="Restart" :disabled="!canRestart" :click="restart" class="mx-2" />
    
                <span class="status-border" :class="{ 'green-gradient': canDeprovision, 'red-gradient': !canDeprovision }">
                    {{ vmData?.status || 'Deprovisioned' }}
                </span>
            </template>
        </div>

        <div class="full-width">
            <span id="search">
                <iws-search v-if="!_isNullOrEmpty(device?.cameraConfigurations)" :value.sync="searchKey" />
            </span>

            <span id="add-new">
                <iws-button text="Discover Cameras" type="outline-light" append-icon="fab fa-searchengin" :click="onDiscoverCameras" class="mr-2" />

                <iws-button text="New Camera" append-icon="fas fa-plus" :click="addCamera" />
            </span>
        </div>

        <template v-if="!_isNullOrEmpty(device?.cameraConfigurations)">
            <iws-table
                :columns="columns"
                
                :items="device?.cameraConfigurations"

                :filter="searchKey"
                :sortByCol.sync="sortByCol"
                :maxPageSize="12"
            >      
                <template #cell_online="{ data }">
                    <iws-checkbox :value.sync="data.item.online" />
                </template>
                <template #cell_publish="{ data }">
                    <iws-checkbox :value.sync="data.item.publish" />
                </template>
                <template #cell_status="{ data }">
                    <span class="circle" :class="{ 'green-gradient': data?.item?.online, 'red-gradient': !data?.item?.online }"></span>
                </template>
                <template #cell_actions="{ data }">
                    <a :href="`/cameras/viewer/${workOrderId}/${data?.item?.id}/${data?.item?.primaryStream?.id}`">
                        <iws-button type="outline-primary" icon="far fa-eye" :disabled="_isFalsy(data?.item?.primaryStream?.id)" />
                    </a>

                    <a :href="`/cameras/settings/${workOrderId}/${device?.deviceID}/${data?.item?.id}`" class="ml-3 mr-2">
                        <iws-button type="outline-light" icon="fas fa-cog" />
                    </a>

                    <iws-button type="outline-danger" icon="fas fa-trash-alt" :click="() => deleteCamera(data.item)" />
                </template>
            </iws-table>

            <div class="form-container">
                <label>
                    VM Data
                </label>

                <div class="row">
                    <div class="col-3">
                        <iws-input :value.sync="device.workOrderId" label="Work Order" required />
                    </div>

                    <div class="col-3" id="last-heartbeat">
                        <iws-input :value="formatHeartbeatTime(vmData?.heartbeatTime)" label="Last Heartbeat" disabled />
                    </div>
                </div>
            </div>

            <div class="form-container">
                <label>
                   Local Stream Quality
                </label>

                <div class="row">
                    <div class="col-3">
                        <iws-select
                            label="Resolution"
                            :value.sync="device.localResolution"
                            :options="streamHeightOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>

                    <div class="col-3">
                        <iws-select
                            label="FPS"
                            :value.sync="device.localFPS"
                            :options="fpsOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>

                    <div class="col-3">
                        <iws-select
                            label="Birrate"
                            :value.sync="device.localBitrate"
                            :options="bitrateOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-3">
                        <iws-select
                            label="Key Frame Interval Factor"
                            :value.sync="device.localKeyFrameIntervalFactor"
                            :options="keyFrameIntervalFactorOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>

                    <div class="col-3">
                        <iws-select
                            label="Encoder Preset"
                            :value.sync="device.localPreset"
                            :options="presetOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>

                    <div class="col-3">
                        <iws-select
                            label="Quality Preset"
                            :value.sync="device.localQualityPreset"
                            :options="qualityPresetOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>
                </div>
            </div>
            <div class="form-container">
                <label>
                   Cloud Stream Quality
                </label>

                <div class="row">
                    <div class="col-3">
                        <iws-select
                            label="Resolution"
                            :value.sync="device.cloudResolution"
                            :options="streamHeightOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>

                    <div class="col-3">
                        <iws-select
                            label="FPS"
                            :value.sync="device.cloudFPS"
                            :options="fpsOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>

                    <div class="col-3">
                        <iws-select
                            label="Birrate"
                            :value.sync="device.cloudBitrate"
                            :options="bitrateOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-3">
                        <iws-select
                            label="Key Frame Interval Factor"
                            :value.sync="device.cloudKeyFrameIntervalFactor"
                            :options="keyFrameIntervalFactorOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>

                    <div class="col-3">
                        <iws-select
                            label="Encoder Preset"
                            :value.sync="device.cloudPreset"
                            :options="presetOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>

                    <div class="col-3">
                        <iws-select
                            label="Quality Preset"
                            :value.sync="device.cloudQualityPreset"
                            :options="qualityPresetOptions"
                            display-name="text"
                            value-name="value"
                        />
                    </div>
                </div>
            </div>

            <div class="form-container">
                <label>
                    Pressure Threshold Settings
                </label>

                <div class="row">
                    <div class="col-3">
                        <iws-input :value.sync="device.pressureUpperThreshold" label="Pressure Upper Threshold" type="number" min="0" />
                    </div>

                    <div class="col-3">
                        <iws-input :value.sync="device.pressureLowerThreshold" label="Pressure Lower Threshold" type="number" min="0" />
                    </div>
                </div>
            </div>
            <div class="form-container">
                <label>
                    Local Archive Settings
                </label>

                <div class="row">
                    <div class="col-3">
                        <iws-input :value.sync="device.localVodArchivalDays" label="VOD archival time (days)" type="number" min="0" />
                    </div>

                    <div class="col-3">
                        <iws-input :value.sync="device.localVodChunkSizeHours" label="VOD chunk size (hours)" type="number" min="0" />
                    </div>

                    <div class="col-3">
                        <iws-input :value.sync="device.minStorageThreshold" label="Min. Storage Threshold (GB)" type="number" min="0" />
                    </div>
                </div>
            </div>

            <div class="form-container">
                <label>
                    Cloud Archive Settings
                </label>

                <div class="row">
                    <div class="col-3">
                        <iws-input :value.sync="device.cloudVodArchivalDays" label="VOD archival time (days)" type="number" min="0" />
                    </div>

                    <div class="col-3">
                        <iws-input :value.sync="device.cloudVodChunkSizeHours" label="VOD chunk size (hours)" type="number" min="0" />
                    </div>
                </div>
            </div>

            <div class="page-save-action full-width">
                <iws-button text="Save" :click="saveDevice" :disabled="vmData?.status == 'Provisioning'" />
            </div>
        </template>
        <div v-else class="no-items clickable" @click="addCamera()">
            <h2 class="danger-text-color">
                No cameras configured for this device
            </h2>
            <h3>
                Click here to create one
            </h3>
        </div>
    </div>

    <add-camera-modal ref="AddCameraModal" />
    <discover-cameras-modal ref="discoverCamsModal" />
    <keychain-modal ref="KeychainModal" />
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, toast } = GlobalFunctions;

import axios from 'axios';

import AddCameraModal from './AddCameraModal.vue';
import DiscoverCamerasModal from './DiscoverCamerasModal.vue';
import KeychainModal from './KeychainModal.vue';

export default {
    props: [ 'user', 'workOrderId', 'device' ],

    components: {
        AddCameraModal,
        DiscoverCamerasModal,
        KeychainModal
    },

    data: () => ({
        vmData: null,
        polling: {},

        columns: [ 
            {
                key: "displayName",
                label: "Camera ID",
            }, {
                key: "hostname",
                label: "Hostname",
            }, {
                key: "brand",
                label: "Brand",
            }, {
                key: "model",
                label: "Model",
            }, {
                key: "online",
                label: "On/Off",
            }, {
                key: "publish",
                label: "Publish",
            }, {
                key: "status",
                label: "Status",
            }, {
                label: null,
                key: 'actions',
                showHeader: false,
                sortable: false
            }
        ],
        searchKey: null,
        sortByCol: 'cloudName',

        resolve: null,

        streamHeightOptions: [
            { value: null, text: "Use source" },
            { value: 480, text: "480" },
            { value: 576, text: "576" },
            { value: 720, text: "720" },
            { value: 960, text: "960" },
            { value: 1080, text: "1080" },
        ],
        fpsOptions: [
            { value: 5, text: "5" },
            { value: 10, text: "10" },
            { value: 15, text: "15" },
            { value: 20, text: "20" },
            { value: 25, text: "25" },
        ],
        bitrateOptions: [
            { value: 1000000, text: "1,000" },
            { value: 1500000, text: "1,500" },
            { value: 2500000, text: "2,500" },
            { value: 4000000, text: "4,000" },
            { value: 5000000, text: "5,000" },
            { value: 7500000, text: "7,500" },
            { value: 8000000, text: "8,000" },
            { value: 12000000, text: "12,000" },
        ],
        keyFrameIntervalFactorOptions: [
            { value: null, text: "Use source" },
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
            { value: 4, text: "4" },
        ],
        presetOptions: [
            { value: "slower", text: "Slower" },
            { value: "slow", text: "Slow" },
            { value: "medium", text: "Medium" },
            { value: "fast", text: "Fast" },
            { value: "faster", text: "Faster" },
        ],
        qualityPresetOptions: [
            { value: "high", text: "High" },
            { value: "medium", text: "Medium" },
            { value: "low", text: "Low" },
            { value: "Custom", text: "Custom" }
        ]
    }),

    computed: {
        canProvision() {
            // If we can't access the status info, it likely hasn't been provisioned
            return !this.vmData?.status || this.vmData?.status == 'Deprovisioned';
        },
        canDeprovision() {
            return this.vmData?.status == 'Online';
        },
        canRestart() {
            return this.vmData?.status == 'Online';
        }
    },

    methods: {
        _isNullOrEmpty: (value) => isNullOrEmpty(value),
        _isFalsy: (value) => isFalsy(value),

        createTraceId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0, 
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },
        
        provision() {
            return GlobalFunctions.iwsConfirm({
                title: 'Provision Device',
                body: `Are you sure you want to provision this device?`,
                width: '400px'
            }).then(_answer => {
                if (_answer)
                    return axios.post(`/cameras/virtual-machines/${this.device.deviceID}`).then(_ => {
                        return new Promise(resolve => this.resolve = resolve);
                    }).catch(_error => {
                        return toast({
                            title: 'Failed to provision device',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
            });
        },
        deprovision() {
            return GlobalFunctions.iwsConfirm({
                title: 'Deprovision Device',
                body: `Are you sure you want to deprovision this device?`,
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                if (_answer)
                    return axios.delete(`/cameras/virtual-machines/${this.device.deviceID}`).then(_ => {
                        return new Promise(resolve => this.resolve = resolve);
                    }).catch(_error => {
                        return toast({
                            title: 'Failed to deprovision device',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
            });
        },
        restart() {
            return GlobalFunctions.iwsConfirm({
                title: 'Restart Device',
                body: `Are you sure you want to restart this device? This devices cameras will be unavailable for a few minutes`,
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                if (_answer)
                    return axios.post(`/cameras/virtual-machines/restart/${this.device.deviceID}`).then(_ => {
                        location.reload();
                    }).catch(_error => {
                        return toast({
                            title: 'Failed to restart device',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
            });
        },

        fetchVmData() {
            return axios.get(`/cameras/virtual-machines/${this.device.deviceID}`).then(_result => {
                if (!isFalsy(_result?.data)) {
                    // Since the provision/deprovision apis resolve before the VM is actually updated, 
                    // continue holding the load state until the action is fully completed the data reflects that
                    if (!isFalsy(this.resolve) && _result.data?.status != this.vmData?.status && _result.data?.status != 'Provisioning') {
                        this.resolve();
                        this.resolve = null;
                    }

                    this.vmData = _result.data;
                }
            }).catch(_error => {
                return toast({
                    title: 'Failed to fetch VM data changes',
                    body: _error.message,
                    variant: 'error'
                });
            });
        },
        pollVM() {
            this.fetchVmData();
            this.polling = setInterval(this.fetchVmData, 5000);
        },
        clearVMPoll() {
            clearInterval(this.polling);
        },

        formatHeartbeatTime(time) {
            // No data until it has been provisioned
            if (this.canProvision)
                return 'N/A';
            
            const date = new Date(time);
            if (date.getTime() > 0)
                return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
            return 'Waiting for heartbeat...';
        },

        onDiscoverCameras() {
            return this.$refs.discoverCamsModal.open(this.device);
        },
        addCamera() {
            return this.$refs.AddCameraModal.open().then(camera => {
                if (!isFalsy(camera))
                    return axios.post(`/cameras/${this.device.deviceID}/camera`, camera).then(_ => {
                        location.reload();
                    }).catch(_error => {
                        return toast({
                            title: 'Failed to create camera',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
            });
        },
        deleteCamera(camera) {
            return GlobalFunctions.iwsConfirm({
                title: 'Delete Camera',
                body: `Are you sure you want to delete "${camera.cloudName || 'this camera'}"? This action cannot be undone!`,
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                if (_answer)
                    return axios.delete(`/cameras/${this.device.deviceID}/camera/${camera.id}?traceId=${this.createTraceId()}`, { traceId: this.createTraceId() }).then(_ => {
                        location.reload();
                    }).catch(_error => {
                        return toast({
                            title: 'Failed to delete camera',
                            body: _error.message,
                            variant: 'error'
                        });
                    });
            })
        },

        saveDevice() {
            return axios.post(`/cameras/devices/${this.device.deviceID}`, this.device).catch(_error => {
                return toast({
                    title: 'Failed to save changes',
                    body: _error.message,
                    variant: 'error'
                });
            });
        },

        openAccountsModal() {
            return this.$refs.KeychainModal.open(this.device);
        }
    },

    mounted() {
        this.pollVM();
    },
    destroyed() {
        this.clearVMPoll
    }
};
</script>

<style>
    #last-heartbeat .form-control:disabled {
        background-color: #343A40 !important;
        color: var(--primary-text-color);
    }
    .circle {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
    .status-border {
        position: relative;
        top: 2px;

        border-radius: 25px;
        border: 2px solid none;
        padding: 7.5px 10px;
    }

    .green-gradient {
        background-image: linear-gradient(to bottom right, #39b599 , #15d766);
    }
    .yellow-gradient {
        background-image: linear-gradient(to bottom right, #FFCC33, #FF9933);
    }
    .red-gradient {
        background-image: linear-gradient(to bottom right, #F46D60 , #E93560);
    }
</style>